import getConfig from '../../utils/config';

import {
  getHierarchy as mockGetHierarchy,
  createNode as mockCreateNode,
  updateNode as mockUpdateNode,
  deleteNode as mockDeleteNode,
} from './mock-hierarchy-service';

//
// Set useMockHierarchyService in config.js to true to use the mock service instead of the live one.
//
const { useMockHierarchyService } = getConfig();

const BASE_URL = process.env.NODE_ENV === 'production'
    ? 'https://hierarchy-us-east-1.rbh-robots-prod.nikecloud.com'
    : 'https://hierarchy-us-east-1.rbh-robots-test.nikecloud.com';

const URL = `${BASE_URL}/hierarchy-ui/nodes/v1`;

/**
 * @typedef {Object} NewHierarchyNode
 * @property {string} nodeType
 * @property {string} name
 * @property {string} [parentId]
 */

/**
 * @typedef {Object} PersistedHierarchyNode
 * @property {string} id
 * @property {string} nodeType
 * @property {string} name
 * @property {string} [parentId]
 */

/**
 * @param {string} accessToken
 * @returns {Promise<PersistedHierarchyNode[]>}
 */
export const getHierarchy = async (accessToken) => (useMockHierarchyService
  ? mockGetHierarchy()
  : fetch(URL, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      method: 'GET',
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error fetching hierarchy: ${response.statusText}`);
      }
      return response.json();
    })
    .then((data) => data.objects)
);

/**
 * @param {NewHierarchyNode} newNode
 * @param {string} accessToken
 * @returns {Promise<PersistedHierarchyNode[]>}
 */
export const createNode = async (newNode, accessToken) => (useMockHierarchyService
  ? mockCreateNode(newNode)
  : fetch(URL, {
      body: JSON.stringify(newNode),
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error creating node: ${response.statusText}`);
      }
      return getHierarchy(accessToken);
    }));

/**
 * @param {PersistedHierarchyNode} updatedNode
 * @param {string} accessToken
 * @returns {Promise<PersistedHierarchyNode[]>}
 */
export const updateNode = async (updatedNode, accessToken) => (useMockHierarchyService
  ? mockUpdateNode(updatedNode)
  : fetch(URL, {
      body: JSON.stringify(updatedNode),
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error updating node: ${response.statusText}`);
      }
      return getHierarchy(accessToken);
    }));

/**
 * @param {PersistedHierarchyNode} nodeToDelete
 * @param {string} accessToken
 * @returns {Promise<PersistedHierarchyNode[]>}
 */
export const deleteNode = async (nodeToDelete, accessToken) => (useMockHierarchyService
  ? mockDeleteNode(nodeToDelete)
  : fetch(`${URL}`, {
      body: JSON.stringify(nodeToDelete),
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error deleting node: ${response.statusText}`);
      }
      return getHierarchy(accessToken);
    }));
